/* ==========================================================================
    FORMIE FORMS
   ========================================================================== */

.fui-btn {
  @apply btn btn--lg btn--neon;
}

.fui-btn-container {
  @apply mt-10;
}

/* Make texty-type inputs look like text inputs */
.fui-input {
  @apply
    border border-dark-grey
    p-6
    transition duration-200 ease-in-out
    w-full
    text-lg
    tracking-tight
  ;
}

.fui-row {
  + .fui-row {
    @apply
      mt-5
    ;
  }
}

.fui-select-field .fui-input-container {
  @apply select;
}

.fui-page [data-conditionally-hidden="true"] {
  @apply hidden;
}

.fui-alert {
  @apply 
    border
    p-2
    mb-5
  ;
}

.fui-checkbox {
  @apply
    flex
    items-center
    text-dark-grey
    gap-2
  ;
}

.fui-checkbox-input {
  @apply 
    border border-dark-grey
    p-2
    m-0
    w-8 h-8
  ;
}

.fui-checkbox-label {
  @apply
    font-normal
    m-0
  ;
}

.fui-error-message {
  @apply
    bg-light-salmon
    p-1
    text-black
  ;
}

.fui-alert-error {
  @apply
    border-salmon
    bg-light-salmon
    text-black
  ;
}

/* Faux textarea (contenteditable div) */
.textarea {
  @apply
    p-6 border input border-turquoise bg-lighter-blue
  ;

  p + p {
    @apply mt-4;
  }
}