/* ==========================================================================
    FIGURES
   ========================================================================== */

figure {
  @apply m-0;
}

figcaption {
  @apply
    text-sm
    text-navy
    opacity-70
    border-b
    border-grey
    py-4
  ;
}