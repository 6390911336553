/* ==========================================================================
    SELECTS
   ========================================================================== */

.select {
  @apply bg-white
    border
    border-dark-grey
    flex
    items-center
    min-h-80
    relative;

  @screen md {
  }

  &:hover {
  }

  select {
    @apply absolute
      appearance-none
      bg-transparent
      border-transparent
      bottom-0
      p-6
      top-0
      w-full
      tracking-tight
      text-lg;

    /* hide ie drop-down arrow */
    &::-ms-expand {
      @apply hidden;
    }

    /* undo the firefox inner focus ring */
    &:focus:-moz-focusring {
      @apply text-transparent;

      text-shadow: 0 0 0 #525252;
    }
  }

  .select__icon {
    @apply absolute
      pointer-events-none
      w-4;

    right: 1em;
  }
}
