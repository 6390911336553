/* ==========================================================================
    HEADINGS
=========================================================================== */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  @apply m-0 leading-tight tracking-tight;
}

/* Default h sizing */
h1,
.h1 {
  @apply text-3xl;
}

h2,
.h2 {
  @apply text-2xl;
}

h3,
.h3 {
  @apply text-xl;
}

h4,
.h4 {
  @apply text-md;
}

/* Larger headings */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  /* Turn on kerning, common and discretionary ligatures, contextual alternates,
  titling caps and swashes for larger headings. Because sexy type. */
  font-feature-settings: "calt", "clig", "dlig", "kern", "liga", "swsh", "titl";
}

/* Do responsives!! */
@screen md {
  h1,
  .h1 {
    @apply text-4xl;
  }

  h2,
  .h2 {
    @apply text-3xl;
  }

  h3,
  .h3 {
    @apply text-2xl;
  }

  h4,
  .h4 {
    @apply text-lg;
  }
}

@screen lg {
  h1,
  .h1 {
    @apply text-5xl;
  }

  h2,
  .h2 {
    @apply text-4xl;
  }

  h3,
  .h3 {
    @apply text-3xl;
  }
}

@screen xl {
  h1,
  .h1 {
    @apply text-6xl;
  }

  h2,
  .h2 {
    @apply text-5xl;
  }

  h3,
  .h3 {
    @apply text-3xl;
  }

  h4,
  .h4 {
    @apply text-xl;
  }
}
