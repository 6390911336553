/* ==========================================================================
    Create a theme specific border
   ========================================================================== */

@layer utilities {
  @variants hover, focus, group-hover, group-focus {
    .border-ui {
      border-color: var(--color-border-ui);
    }

    .border-link {
      border-color: var(--color-link);
    }
  }
}
