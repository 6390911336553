/* ==========================================================================
    LEDE
   ========================================================================== */

.lede {
  @apply leading-tight text-base;

  @screen md {
    @apply text-md;
  }

  @screen xl {
    @apply text-lg;
  }
}
