/* ==========================================================================
    SUB AND SUPERSCRIPTS
   ========================================================================== */
sup {
  font-variant-position: super;
}

sub,
.sinf /* A selector for scientific inferiors */
 {
  font-variant-position: sub;
}
