/*  ==========================================================================
    HTML
    ========================================================================== */

/**
 * 1. Border-box *all the things*, as suggested in:
 *    http:// paulirish.com/2012/box-sizing-border-box-ftw
 *
 *
 * 2. Allow adding a border to an element by just adding a border-width.
 *
 *    By default, the way the browser specifies that an element should have no
 *    border is by setting it's border-style to `none` in the user-agent
 *    stylesheet.
 *    Reference: https://github.com/tailwindcss/tailwindcss/pull/116
 */

html {
  box-sizing: border-box; /* 1 */
  text-size-adjust: 100%;
  /* Native smooth scroll */
  scroll-behavior: smooth;
  /* Unset if a user has not set reduced-motion preference */
  @media (prefers-reduced-motion) {
    scroll-behaviour: initial;
  }
}

* {
  &,
  &::before,
  &::after {
    border-style: solid; /* 2 */
    border-width: 0; /* 2 */
    box-sizing: inherit;
  }
}
