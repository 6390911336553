/* ==========================================================================
    IMAGES
   ========================================================================== */

/* Fluid images. */
img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

/*
* Darkmode images
* Disabled by default as this is expensive css!

@media (prefers-color-scheme: dark) {
  img {
    filter: brightness(.85) contrast(.9);
  }
}

*/
