/* ==========================================================================
    SECTION HELPERS
   ========================================================================== */

.section {
  @apply py-12;

  @screen xl {
    @apply py-16;
  }
}

.section--lg {
  @apply py-24;

  @screen xl {
    @apply py-48;
  }
}

.section--sm {
  @apply py-4;

  @screen xl {
    @apply py-6;
  }
}

@responsive {
  .section-spacer {
    @apply my-15 xl:my-30;
  }
}
