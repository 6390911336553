/* ==========================================================================
    TABLE
  ========================================================================== */

table {
  @apply border-collapse w-full;

  @screen lg {
    @apply text-md;
  }

  /* Enable tabular, lining numerals and slashed zero */
  font-feature-settings: "tnum", "lnum", "zero";
}

th {
  @apply font-bold;
}

th,
td {
  @apply border
    border-ui

    p-2
    text-left;
}
