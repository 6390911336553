input,
textarea {
  /* Set / reset some base form styles at the highest level */
  color: currentColor;
  font: inherit;

  &:disabled {
    @apply cursor-not-allowed;
  }

  &::placeholder {
    opacity: 1;
  }

  /* Make texty-type inputs look like text inputs */
  &.input {
    @apply
      border
      p-6
      transition duration-200 ease-in-out
      w-full
    ;

    /* Default styles for texty-type inputs without errors */
    &:not(.has-errors) {
      @apply bg-white;
    }
  }
}

/* Set input type border colours */
input.input {
  @apply 
    border-dark-grey
    text-lg
    tracking-tight
  ;
}

/* Set textarea colours */
textarea.input {
  @apply 
    border-turquoise
  ;

  &:not(.has-errors) {
    @apply bg-lighter-blue;
  }
}

.StripeElement {
  box-sizing: border-box;
  @apply
    border
    border-dark-grey
    p-6
    transition duration-200 ease-in-out
    w-full
    bg-white;
}
