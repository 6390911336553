/* ==========================================================================
    GRADIENTS
   ========================================================================== */

.bg-gradient-v-40 {
  background-image: linear-gradient(
    0deg,
    #d8d9d6 0,
    #d8d9d6 40rem,
    transparent 0,
    transparent
  );
}

.bg-gradient-v-20--green {
  background-image: linear-gradient(
    0deg,
    #00c7b1 0,
    #00c7b1 20rem,
    transparent 0,
    transparent
  );
}
