/* ==========================================================================
    PROSE - Longform content, the stuff we hope someone else will write!
  ========================================================================== */

.prose {
  @screen md {
    @apply text-base;
  }

  @screen xl {
    @apply text-md;
  }

  /* Heading need some space */
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mt-12 mb-4;
  }

  /* Next-gen link state pseudo matching */
  a:matches(:focus, :hover, :active) {
    @apply text-navy;
  }

  /* Lists */
  & > dl,
  & > ol,
  & > ul {
    @apply leading-normal;
  }

  p {
    @apply leading-normal;

    /* Don’t cause eye strain */
    max-width: var(--max-width-copy);

    & + p {
      @apply mt-4;
    }
  }

  /* Drop cap */
  &.dropcap > p:first-child {
    &:first-letter {
      @apply float-left
        bg-yellow
        text-3xl
        leading-none
        font-bold
        inline-block
        mr-2
        mt-1;

      padding: 10px 16px;

      @screen md {
        @apply mt-2;
        padding: 10px 18px;
      }

      @screen xl {
        @apply text-4xl;
      }
    }
  }

  code,
  pre {
    @apply font-mono;
  }

  /* Lists */
  ol,
  ul {
    @apply list-none;
  }

  ol > li:before,
  ul > li:before {
    content: "";
    @apply bg-turquoise
      rounded-full
      absolute
      left-0;
  }

  li > ol,
  li > ul {
    @apply mt-4;
  }

  /* Unordered lists */
  ul > li {
    @apply relative
      pl-6
      mb-2;
  }

  ul > li:before {
    content: "";
    @apply top-1.5
      w-3
      h-3;

    @screen md {
      @apply top-2;
    }

    @screen xl {
      @apply top-2.5;
    }
  }

  /* Ordered lists */
  ol {
    counter-reset: list;
  }

  ol > li {
    counter-increment: list;
    @apply relative
      pl-16
      mb-4
      mt-11;

    @screen 2xl {
      @apply pl-20;
    }
  }

  ol > li:before {
    content: counter(list);
    @apply font-bold
      text-center
      pt-1.5
      text-lg
      w-12
      h-12
      -top-2.5;

    @screen 2xl {
      @apply pt-2
        text-xl
        w-16
        h-16
        -top-3;
    }
  }
}
