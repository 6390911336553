/* ==========================================================================
    BLOCKQUOTES
   ========================================================================== */

blockquote {

  @apply
    border-l
    border-navy
    text-navy
    pl-4
    mx-0;

    @screen md {
      @apply pl-8;
    }

  &,
  & p {
    @apply leading-normal
    tracking-tighter
    text-lg;

    @screen sm {
      @apply text-xl;
    }

    @screen xl {
      @apply text-2xl;
    }
  }
}


