/**
 * variables.css
 *
 * Custom CSS properties are set here.
 *
 */

:root {
  --color-body: hsla(0, 0%, 0%, 0.8);
  --color-list-marker: var(--color-ui-border);
  --color-bg-ui: hsla(0, 0%, 0%, 0.05);
  --color-border-ui: hsla(0, 0%, 0%, 0.15);
  --color-link: #008575;
  --max-width-copy: 70ch;
  --max-width-container: 1640px;
  --width-container: 90vw;
  --width--bleed: 5vw;
  /*--masthead-height: 175px;
  --masthead-height-md: 100px;
  --masthead-height-xl: 125px;
  --masthead-height-2xl: 140px;*/
}

/* You asked for dark? Try some funk! */
@media (prefers-color-scheme: dark) {
  :root {
    --color-bg-ui: hsla(0, 0%, 100%, 0.1);
    --color-border-ui: hsla(0, 0%, 100%, 0.2);
    --color-link: hsl(213.1, 93.9%, 67.8%);
  }
}
