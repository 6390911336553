/* ==========================================================================
    CHECKBOXES
   ========================================================================== */

.checkbox {
  @apply flex flex-wrap items-center -ml-2;

  /* Hide the real checkbox */
  & input {
    @apply absolute opacity-0;
  }

  /* Create a faux checkbox */
  & .checkbox__label {
    @apply inline-flex
      items-center
      px-2
      py-1
      font-normal;

    &::before {
      @apply border-2
        bg-white
        flex
        items-center
        justify-center
        mr-4
        h-10 w-10
        transition duration-200 ease-in-out
        font-bold
        text-xl;

      content: "";
    }
  }

  /* Make disabled controls look like they are disabled. */
  & input:disabled + .checkbox__label::before {
  }

  /* Update the faux checkbox styles on checked or focussed states */
  & input:checked + .checkbox__label::before,
  & input:focus + .checkbox__label::before {
  }

  & input:checked + .checkbox__label::before {
    content: "✓";
  }

  /* Apply disabled checkbox styles to the checkbox label */
  & input:disabled + .checkbox__label {
    @apply cursor-not-allowed;
  }

  /* Update faux checkbox styles on user action */
  & input:not(:disabled) + .checkbox__label:hover,
  & input:not(:disabled) + .checkbox__label:focus,
  & input:not(:disabled) + .checkbox__label:focus:active {
    &::before {
    }
  }
}

.redactor-modal .checkbox input {
  @apply opacity-100;
}

.checkbox-tag {
  @apply flex flex-wrap items-center;

  /* Hide the real checkbox */
  & input {
    @apply absolute opacity-0;
  }

  /* Create a faux checkbox */
  & .checkbox__label {
    @apply border


      p-1 px-2
      font-normal
      transition
      duration-200
      text-sm;
  }

  /* Make disabled controls look like they are disabled. */
  & input:disabled + .checkbox__label {
  }

  & input:hover + .checkbox__label,
  & input:focus + .checkbox__label {
  }

  /* Update the faux checkbox styles on checked states */
  & input:checked + .checkbox__label {
    @apply text-white;
  }

  /* Apply disabled checkbox styles to the checkbox label */
  & input:disabled + .checkbox__label {
    @apply cursor-not-allowed;
  }

  /* Update faux checkbox styles on user action */
  & input:not(:disabled) + .checkbox__label:hover,
  & input:not(:disabled) + .checkbox__label:focus,
  & input:not(:disabled) + .checkbox__label:focus:active {
    &::before {
    }
  }
}
