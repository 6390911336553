/* ==========================================================================
    ANCHORS
   ========================================================================== */

a {
  @apply duration-250
    ease-out
    motion-safe:transition-all;

  color: var(--color-link);

  /* Better-looking links in better browsers. */
  text-decoration-skip-ink: auto;

  /* Next-gen link state pseudo matching */
  &:matches(:focus, :hover, :active) {
    text-decoration: underline;
  }
}

.a--wrap {
  &,
  &:matches(:focus, :hover, :active) {
    text-decoration: none;
  }
}
