.accordion {

  .active {
    svg {
      @apply
        transform
        rotate-180
      ;
    }
  }
}
