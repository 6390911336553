
#mc_embed_signup:not(.no-style) {

  @apply
    bg-white p-8
  ;

  h2 {
    @apply h3;
  }

  .indicates-required {
    @apply float-right text-sm;
  }

  .mc-field-group {
    @apply my-4 clear-both;
  }

  fieldset.mc-field-group {
    @apply
      border-none
      p-0
    ;
  }

  .refferal_badge {
    display: none !important;
  }

  input.email,
  input.text {
    @apply 
      border
      p-3 lg:p-6
      transition duration-200 ease-in-out
      w-full
      border-dark-grey
      text-lg
      tracking-tight
    ;
  }

  .button {
    @apply btn btn--lg btn--neon;
  }


  label.checkbox {
    @apply flex flex-nowrap items-start gap-2 w-full;
  }

  label.checkbox input {
    @apply
      opacity-100
      relative
    ;
  }

  p {
    @apply
      my-4
    ;
  }

  .content__gdprLegal p,
  .content__gdpr fieldset + p {
    @apply text-xs;
  }

  .mc-field-group.input-group ul {
    @apply list-none;
  }

  .mc-field-group.input-group li {
    @apply flex flex-nowrap items-start gap-2 w-full;
  }
}

#mc_embed_shell:not(.no-style) {

  @apply
    bg-navy
    p-8
    w-full
    xl:w-2/3
  ;
  
}