/* ==========================================================================
    BUTTONS
   ========================================================================== */

.btn {
  @apply antialiased
    bg-navy
    border-2 border-transparent
    cursor-pointer
    duration-200
    ease-in-out
    font-bold
    inline-flex
    items-center
    justify-center
    leading-none
    no-underline
    p-3 px-6
    relative
    rounded-xl
    text-white
    transition;

  /* Override button element default styles */
  font-family: inherit;

  /* Interactive button styles */
  &:matches(:focus, :hover, :active) {
    text-decoration: none;
  }

  /* Disabled button styles */
  &.disabled {
    &,
    &:matches(:focus, :hover, :active) {
      @apply cursor-not-allowed opacity-50;
    }
  }
}

/* Next-gen link state pseudo matching */
.btn:matches(:focus, :hover, :active) {
  @apply bg-mid-blue;
}

.btn--blue {
  @apply bg-light-blue text-navy;
}

.btn--green {
  @apply bg-light-green;
}

.btn--neon {
  @apply bg-neon-turquoise text-navy;
}

.btn--white {
  @apply bg-white text-rich-green;

  &:matches(:focus, :hover, :active) {
    @apply text-navy;
  }
}

.btn--border-green {
  @apply bg-transparent border-light-green;

  &:matches(:focus, :hover, :active) {
    @apply bg-transparent border-mid-blue;
  }
}

.btn--border-navy {
  @apply bg-transparent border-navy text-navy;

  &:matches(:focus, :hover, :active) {
    @apply bg-transparent border-mid-blue;
  }
}

.btn--border-neon {
  @apply bg-transparent border-neon-turquoise;

  &:matches(:focus, :hover, :active) {
    @apply bg-transparent border-mid-blue;
  }
}

.btn--border-white {
  @apply bg-transparent border-white;

  &:matches(:focus, :hover, :active) {
    @apply bg-transparent border-mid-blue;
  }
}

.btn--lg {
  @apply py-4 rounded-2xl text-base;

  @screen md {
    @apply text-md;
  }

  @screen xl {
    @apply text-lg;
  }
}

.btn-tab {
  @apply antialiased
    bg-transparent
    border-b-2 border-light-grey
    cursor-pointer
    duration-200
    ease-in-out
    leading-none
    no-underline
    p-2 px-4
    rounded-none
    text-navy
    mb-4
    font-sans
    text-sm
    transition;

    &.selected {
      @apply border-navy;
    } 
}
