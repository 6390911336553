

.donation-toggle {
  @apply opacity-70;

  &.active {
    @apply
      opacity-100
      relative
      ;

      &:before {
        content: "";
        @apply
          w-1
          h-full
          -left-1
          bottom-0
          bg-navy
          absolute
        ;

        @screen md {
          @apply
            w-full
            h-1
            left-0
            -bottom-1
            ;
        }
      }
  }
}

