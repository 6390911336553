.nav-primary {
  overflow: hidden;

  > li {
    position: relative;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      background-color: white;
      height: 16px;
      width: 16px;
      left: -8px;
      bottom: -8px;
      z-index: 1;
    }
  }
}
