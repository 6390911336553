/* ==========================================================================
    TAGS
   ========================================================================== */

.tag {
  @apply border border-ui
    px-4
    relative
    text-sm
    flex
    items-center
    py-2
    rounded;
}

a.tag {
  @apply transition duration-500 ease-in-out;

  &:active,
  &:hover,
  &:focus {
  }
}
