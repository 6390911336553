/* ==========================================================================
    LISTS
   ========================================================================== */

/*
* There's a reason it's called 'hanging punctuation', bullets should be outdented.
* http://www.markboulton.co.uk/journal/five-simple-steps-to-better-typography-part-2
*/

ol,
ul {
  list-style-position: outside;
  padding: 0;
}

li > ol,
li > ul {
  padding-left: 1.5em;
}