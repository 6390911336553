/* ==========================================================================
    CONTAINER
   ========================================================================== */

@responsive {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--max-width-container);
    width: var(--width-container);
  }
}
