@responsive {
  .columns-2 {
    columns: 2;
  }

  .column-gap-2 {
    column-gap: 0.5rem;
  }

  .column-gap-4 {
    column-gap: 1rem;
  }
}
