.section-nav {
  a.is-active {
    &:before {
      content: "";
      @apply
        w-px
        h-full
        bg-navy
        absolute
        top-0
        -left-px ;
    }
  }
}