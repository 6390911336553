.hero {
  /*
  min-height: calc(100vh - var(--masthead-height));

  @screen md {
    min-height: calc(100vh - var(--masthead-height-md));
  }

  @screen xl {
    min-height: calc(100vh - var(--masthead-height-xl));
  }

  @screen 2xl {
    min-height: calc(100vh - var(--masthead-height-2xl));
  } */

  .hero-nav {
    margin-left: calc(var(--width--bleed) * -1);
    margin-right: calc(var(--width--bleed) * -1);

    @screen md {
      margin-right: 0;
      padding-left: var(--width--bleed);
    }
  }

  .hero-image-container {
    max-width: calc(50vw + var(--max-width-container) / 2);

    &:before {
      content: "";
      @apply absolute
        bottom-0
        left-0
        right-0
        bg-gradient-to-b
        from-transparent
        to-black
        h-1/2
        opacity-40;
    }
  }

  .hero-image-container--narrow {
    @screen md {
      margin-left: calc(50% + 12px);
      max-width: calc(50% - 12px - 5vw);
    }
  }
}

.hero--home {

  .img-river {

    bottom: -17.5rem;
    left: -20vw;
    margin-left: calc(100vw - (2591px) / 4);

    @screen sm {
      bottom: -3.125rem;
      left: -70vw;
    }

    @screen md {
      margin-left: calc(100vw - (2591px) / 2);
    }

    @screen lg {
      left: -20vw;
    }

    @screen xl {
      bottom: -11.25rem;
      left: -50vw;
    }

    @screen 2xl {
      bottom: -15rem;
    }

    @media screen and (min-width: 2592px) {
      left: auto;
      margin-left: 0;
      bottom: -19rem;
      /* bottom: auto;
      top: 2rem; */
    }
  }

  /* Resize the river through screen sizes */
  svg {
    width: 400vw;
    
    @screen lg {
      width: 2591px;
    }
  }
}
